<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4">
        <h4>Offer's name & message</h4>
      </v-col>
      <!-- <pre>{{ formData }}</pre> -->
      <v-col cols="12" sm="8" class="elevation-form">
        <InputNest fieldName="Offer name" :isValidate="true" label="Offer's name" :model="['formData', 'offerName']" />
        <small class="input-desc mt-2">
          Give your offer a name to manage easily or leave blank and we will automatically generate a name for you
        </small>
        <InputNest
          :itemsSelect="$parent.itemsMessage"
          typeComponent="select"
          label="Offer message"
          :model="['formData', 'offerMessage']"
        />
      </v-col>
    </v-row>
    <v-col cols="12" class="my-2">
      <v-divider class=""></v-divider>
    </v-col>
    <v-row>
      <v-col cols="12" sm="4">
        <h4>Choose Target products</h4>
        <small>If customers add these products/ products in these collections to cart, this offer will pop up </small>
      </v-col>
      <v-col cols="12" sm="8" class="elevation-form">
        <v-radio-group v-model="$parent.formData.targetType" class="ml-3">
          <v-radio value="all" label="All product"> </v-radio>
          <v-radio label="Specific products" value="product">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific products </span>
                <v-btn
                  v-if="$parent.formData.targetType === 'product'"
                  @click="$parent.configDialogSelection({ type: 'products', model: 'targetIds' })"
                >
                  Select products</v-btn
                >
                <span v-if="$parent.formData.targetIds && $parent.formData.targetType === 'product'"
                  >{{ $parent.formData.targetIds.length }} product selected</span
                >
              </div>
            </template>
          </v-radio>
          <v-radio label=" Specific collections" value="collection">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific collections </span>
                <v-btn
                  v-if="$parent.formData.targetType === 'collection'"
                  @click="$parent.configDialogSelection({ type: 'collections', model: 'targetIds' })"
                >
                  Select Collection</v-btn
                >
                <span v-if="$parent.formData.targetIds && $parent.formData.targetType === 'collection'"
                  >{{ $parent.formData.targetIds.length }} Collection selected</span
                >
              </div>
            </template>
          </v-radio>
          <small
            class="text-error"
            v-if="
              ($parent.formData.targetType === 'product' || $parent.formData.targetType === 'collection') &&
                $parent.formData.targetIds.length === 0
            "
            >Please select product(s)/collection(s).
          </small>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-col cols="12" class="my-2">
      <v-divider class=""></v-divider>
    </v-col>
    <v-row>
      <!-- <pre>{{ formData.targetType }} {{ formData.recommendType }}</pre> -->
      <v-col cols="12" sm="4">
        <h4>Choose recommended products</h4>
        <small>
          These products will appear on the popup after the Target product was added, in order to motivate the customers
          to add more
        </small>
      </v-col>
      <v-col cols="12" sm="8" class="elevation-form d-flex">
        <v-radio-group v-model="$parent.formData.recommendType" class="ml-3">
          <v-radio label="Specific products" value="product">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific products </span>
                <v-btn
                  v-if="$parent.formData.recommendType === 'product'"
                  @click="$parent.configDialogSelection({ type: 'products', model: 'recommendIds' })"
                >
                  Select products</v-btn
                >
                <span v-if="$parent.formData.recommendType && $parent.formData.recommendType === 'product'"
                  >{{ $parent.formData.recommendIds.length }} product selected</span
                >
              </div>
            </template>
          </v-radio>
          <v-radio label=" Specific collections" value="collection">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific collections </span>
                <v-btn
                  v-if="$parent.formData.recommendType === 'collection'"
                  @click="$parent.configDialogSelection({ type: 'collections', model: 'recommendIds' })"
                >
                  Select Collection</v-btn
                >
                <span v-if="$parent.formData.recommendType && $parent.formData.recommendType === 'collection'"
                  >{{ $parent.formData.recommendIds.length }} Collection selected</span
                >
              </div>
            </template>
          </v-radio>
          <v-radio value="sameCollection" label="Same collection with target product "> </v-radio>
          <v-radio value="sameNiche" label="Same niche"> </v-radio>
          <v-radio value="sameTitle" label="Same title"> </v-radio>
          <div class="d-flex">
            <v-radio value="relevantCollection" label="Most relevant products use automated rules"> </v-radio>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey lighten-1">
                    mdi-information
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Recommend with this priority:<br />
                - Bought also Bought<br />
                - Best-seller products in the same collection<br />
                - Title Similarity<br />
                - Same collection<br />
                - Relative tags products<br />
                - Random<br />
              </span>
            </v-tooltip>
          </div>
          <small
            class="text-error"
            v-if="
              ($parent.formData.recommendType === 'product' || $parent.formData.recommendType === 'collection') &&
                $parent.formData.recommendIds.length === 0
            "
            >Please select product(s)/collection(s).
          </small>
        </v-radio-group>
        <div>
          <v-checkbox v-model="$parent.formData.sameProductType" label="Same product type"></v-checkbox>
        </div>
      </v-col>
    </v-row>
    <v-col cols="12" class="my-2">
      <v-divider class=""></v-divider>
    </v-col>
    <v-row>
      <v-col cols="12" sm="4">
        <h4 class="d-flex align-center">Offer’s discount <v-switch v-model="$parent.formData.enableDiscount" /></h4>
        <small class="input-desc mt-2">
          Offer discount and scarcity to motivate customers add Up-sell products to cart
        </small>
      </v-col>
      <v-col cols="12" sm="8" class="elevation-form">
        <InputNest
          :sufix="currency.currencyCode()"
          label="Discount percentage"
          :model="['formData', 'percentageDiscount']"
        />
      </v-col>
      <v-dialog :scrollable="false" v-model="$parent.dialog" width="900" content-class="l-dialog">
        <SelectionItems
          :model="$parent.configSelectionItem.model"
          :type="$parent.configSelectionItem.type"
          @returnData="$parent.getDataSelected"
          :dialog.sync="$parent.dialog"
          :dataSelected="$parent.configSelectionItem.data"
          :isSuperShow="true"
        />
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import SelectionItems from '../../component/SelectionItems';
import currency from '@/helpers/currency';
export default {
  components: {
    SelectionItems,
  },
  data() {
    return {
      currency,
    };
  },
  watch: {
    'formData.targetType': function() {
      this.formData.targetIds = [];
    },
    'formData.recommendType': function() {
      this.formData.recommendIds = [];
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
