<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4">
        <h4>Offer's name & message</h4>
      </v-col>
      <v-col cols="12" sm="8" class="elevation-form">
        <InputDefault label="Offer's name" :model="['data', 'name']" />
        <small class="input-desc mt-2">
          Give your offer a name to manage easily or leave blank and we will automatically generate a name for you
        </small>
      </v-col>
    </v-row>
    <v-col cols="12" class="my-2">
      <v-divider class=""></v-divider>
    </v-col>
    <v-row>
      <v-col cols="12" sm="4">
        <h4>Choose Target products</h4>
        <small>If customers add these products/ products in these collections to cart, this offer will pop up </small>
      </v-col>
      <v-col cols="12" sm="8" class="elevation-form">
        <v-radio-group v-model="typeTarget" class="ml-3">
          <v-radio value="all" label="All product"> </v-radio>
          <v-radio label="Specific products" value="products">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific products </span>
                <v-btn v-if="typeTarget === 'products'" @click="dialog = true"> Select products</v-btn>
                <span v-if="data.target">{{ data.target.length }} product selected</span>
              </div>
            </template>
          </v-radio>
          <v-radio label=" Specific collections" value="collections">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific collections </span>
                <v-btn v-if="typeTarget === 'collections'" @click="dialog = true"> Select Collection</v-btn>
                <span v-if="data.target">{{ data.target.length }} product selected</span>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-col cols="12" class="my-2">
      <v-divider class=""></v-divider>
    </v-col>
    <v-row>
      <v-col cols="12" sm="4">
        <h4>Choose recommended products</h4>
        <small>
          These products will appear on the popup after the Target product was added, in order to motivate the customers
          to add more
        </small>
      </v-col>
      <v-col cols="12" sm="8" class="elevation-form">
        <v-radio-group v-model="typeTarget" class="ml-3">
          <v-radio label="Specific products" value="products">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific products </span>
                <v-btn v-if="typeTarget === 'products'" @click="dialog = true"> Select products</v-btn>
              </div>
            </template>
          </v-radio>
          <v-radio label=" Specific collections" value="collections">
            <template v-slot:label>
              <div class="d-flex flex-column pt-20">
                <span> Specific collections </span>
                <v-btn v-if="typeTarget === 'collections'" @click="dialog = true"> Select Collection</v-btn>
              </div>
            </template>
          </v-radio>
          <v-radio value="allss" label="Same collection with target product "> </v-radio>
          <v-radio value="alls" label="Most relevant products use automated rules"> </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-dialog :scrollable="false" persistent v-model="dialog" width="900" content-class="l-dialog">
      <SelectionItems
        :model="configSelectionItem.model"
        :type="configSelectionItem.type"
        @returnData="getDataSelected"
        :dialog.sync="dialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import SelectionItems from '../../component/SelectionItems';
export default {
  components: {
    SelectionItems,
  },
  data() {
    return {
      configSelectionItem: {
        type: 'products',
        model: 'target',
      },
      dialog: false,
      typeTarget: 'all',
      itemsMessage: [
        {
          name: 'Frequently bought with {{product name}}',
          id: 1,
        },
        {
          name: 'Complete your order with {{product name}}',
          id: 2,
        },
        {
          name: 'Best deal today for lucky customers like you',
          id: 3,
        },
      ],
      data: {
        name: '',
        typeMessage: 2,
        target: [],
      },
    };
  },
  methods: {
    getDataSelected(data, model) {
      console.log(data, model);
      this.data[model] = JSON.parse(JSON.stringify(data));
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
